import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";

import CardImage from "../../../shared/components/card-image/card-image";
import ShIcon from "../../../shared/components/sh-icon/sh-icon";
import { UiStateContext } from "../../../shared/contexts/ui-state/UiStateContext";
import { IconPack } from "../../../shared/models/app-data-model";
import { Location } from "../../../shared/services/kendra-api/kendra-api-service";
import "./location-card-desktop.scss";

export type LocationCardProps = {
  location: Location;
  iconPack: IconPack[];
  elementId?: string;
  isKyruusData?: boolean;
  kyruusOriginCity?: string;
  kyruusTabCard?: boolean;
};

const LocationCardDesktop: React.FC<LocationCardProps> = ({
  location,
  iconPack,
  elementId,
  isKyruusData,
  kyruusOriginCity,
  kyruusTabCard
}: LocationCardProps) => {
  const uiStateContext = useContext(UiStateContext);
  const [cardWidth, setCardWidth] = useState<string | string[]>("696px");

  const usingMlocPages = location._source_uri.includes("://");

  useEffect(() => {
    setCardWidth(
      uiStateContext.searchOpen || uiStateContext.embeddedSearchShown ? ["560px", "560px", "560px", "560px", "560px", "696px"] : "696px"
    );
  }, [uiStateContext.embeddedSearchShown, uiStateContext.searchOpen]);

  const defaultCardContent = (
    <>
      <Flex justifyContent="space-between" alignItems="center" flexDirection="row">
        <h6 className="location-title">{location.location_name}</h6>
        {location.location_rating !== "null" && (
          <Flex alignItems="center" justifyContent="space-between" flexDirection="row" flexShrink="0">
            <ShIcon iconPack={iconPack} className="star-icon" iconName="star" />
            <span className="overline rating secondary-text">{location.location_rating}</span>
            <span className="overline reviews secondary-text">({location.location_reviews})</span>
          </Flex>
        )}
      </Flex>

      {location.location_hours !== "null" && (
        <Flex flexDirection="row" alignItems="center">
          <ShIcon iconPack={iconPack} iconName="clock" className="clock" />
          <span className="body-small secondary-text location-hours">{location.location_hours}</span>
        </Flex>
      )}

      {location.street_address && location.street_address !== "null" && (
        <Flex alignItems="center">
          <ShIcon iconPack={iconPack} iconName="map_pin" className="map-pin" />
          <span className="address body-small secondary-text">
            {location.street_address +
              (location.city !== "null" && location.state !== "null" && location.zip_code !== "null"
                ? `, ${location.city}, ${location.state} ${location.zip_code}`
                : "")}
          </span>

          {location.distance && location.distance !== "null" && (
            <span className="body-small secondary-text">
              <span>
                <strong>{location.distance}</strong> miles from
              </span>
              <span className="user-location">
                {uiStateContext?.zipLocation?.zip?.value?.length === 5
                  ? uiStateContext?.zipLocation?.zip.value
                  : uiStateContext?.browserLocation?.latitude === 0
                  ? "Grand Rapids"
                  : "Your Location"}
              </span>
            </span>
          )}
        </Flex>
      )}
    </>
  );

  const kyruusLocationConent = (
    <>
      <Flex alignItems="center">
        <h6 className="location-title kyruus-title">{location.location_name}</h6>
      </Flex>
      {location.street_address && location.street_address !== "null" && (
        <Flex className="address-container" alignItems="center">
          <ShIcon iconPack={iconPack} iconName="map_pin" className="map-pin kyruus-map-pin" />
          <span className="kyruus-card-text body-small">
            {location.street_address +
              (location.city !== "null" && location.state !== "null" && location.zip_code !== "null"
                ? `, ${location.city}, ${location.state} ${location.zip_code}`
                : "")}
          </span>
        </Flex>
      )}
      {location.distance && location.distance !== "null" && (
        <Flex alignItems="center" pt="5px">
          <ShIcon iconPack={iconPack} iconName="direction" className="map-pin kyruus-map-pin" />
          <span className="kyruus-card-text body-small">
            {location.distance} miles from {kyruusOriginCity ? kyruusOriginCity : "Your Location"}
          </span>
        </Flex>
      )}
    </>
  );

  return (
    <a
      href={location._source_uri}
      className="no-decoration results-card"
      id={elementId}
      target={`${usingMlocPages ? "_blank" : "_self"}`}
      rel="noopener"
      data-kendra-token={location.feedback_token}
    >
      <Flex
        alignItems="center"
        marginY={3}
        className={`location-card-desktop ${isKyruusData ? "kyruus-location-card-desktop" : ""} ${kyruusTabCard && "kyruus-tab-card"}`}
        width={cardWidth}
        data-testid={`location-card-desktop-${cardWidth}`}
      >
        <CardImage url={location.location_pic} alt={location.location_name} className={"location-image"} type={"location"} />
        <Flex
          className="info-container"
          flexDirection="column"
          width={1}
          height="100%"
          pb={isKyruusData ? "23px" : 0}
          pt={isKyruusData ? "23px" : "16px"}
          ml={4}
          pr="24px"
        >
          {isKyruusData ? kyruusLocationConent : defaultCardContent}
        </Flex>
      </Flex>
    </a>
  );
};

export default LocationCardDesktop;
