import { GlobalQueryResponse } from 'design-system/shared/models/app-data-model';
import { graphql, StaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';

import App from '../app';

export type LayoutProps = {
  children: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          shSpectrumhealthOrgGlobalSettings {
            ...globalSettingsFragment
          }
          shSpectrumHealthOrgGlobalNav {
            ...globalNavFragment
          }
          shSpectrumhealthOrgPrimaryHeader {
            ...primaryHeaderFragment
          }
          shGlobalAlertsSpectrumhealthOrg {
            ...globalAlertsFragment
          }
          shSpectrumhealthOrgGlobalFooterNav {
            ...globalFooterFragment
          }
          shGlobalEmbedScriptsSpectrumhealthOrg(uid: { eq: "blt9cdcb6ecba3c43b0" }) {
            modular_blocks {
              embed_information {
                title
                div_id
                script
                enabled
                allow_list
                deny_list
                embedded_page_references {
                  url
                }
              }
            }
          }
        }
      `}
      // NOTE: we're going to wrap our actual app-layout in app.tsx
      render={(data: GlobalQueryResponse) => <App data={data}>{children}</App>}
    />
  );
};

export default Layout;
